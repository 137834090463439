import React from "react"
import Secone from "../Components/Secone";
import Sectwo from "../Components/Sectwo";
import Secthree from "../Components/Secthree";
import Coursecategories from "../Components/Coursecategories";
import Footer from "../Components/Footer";
import Secfour from "../Components/Secfour";

const Homep = () => {
    return(
        <div>
            <Secone />
            <Sectwo />
            <Secthree />
            <Secfour />
            <Coursecategories />
            <Footer />
        </div>
    )
}

export default Homep;